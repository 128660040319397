import { useCreateRoutes } from '@ilogix/hooks/useRoute';
import Loadable from '@core/components/LayoutTheme/Loadable';
import { lazy } from 'react';

const WarehouseListPage = Loadable(
  lazy(() => import('./views/tools/manageWarehouse/pages/WarehouseListPage')),
);
const WarehouseFormPage = Loadable(
  lazy(() => import('./views/tools/manageWarehouse/pages/WarehouseFormPage')),
);
const Home = Loadable(lazy(() => import('./views/home/pages/Home')));
const SupplierListPage = Loadable(
  lazy(() => import('./views/tools/manageSupplier/pages/SupplierListPage')),
);
const SupplierFormPage = Loadable(
  lazy(() => import('./views/tools/manageSupplier/pages/SupplierFormPage')),
);
const LocationFormPage = Loadable(
  lazy(() => import('./views/tools/manageLocation/pages/LocationFormPage')),
);
const LocationListPage = Loadable(
  lazy(() => import('./views/tools/manageLocation/pages/LocationListPage')),
);
const SectorFormPage = Loadable(
  lazy(() => import('./views/tools/manageSector/pages/SectorFormPage')),
);
const SectorListPage = Loadable(
  lazy(() => import('./views/tools/manageSector/pages/SectorListPage')),
);
const BalanceListPage = Loadable(
  lazy(() => import('./views/products/balance/pages/BalanceListPage')),
);
const TransactionListPage = Loadable(
  lazy(
    () =>
      import('./views/transaction/transactionList/pages/TransactionListPage'),
  ),
);
const OutFormPage = Loadable(
  lazy(() => import('./views/transaction/out/outRegister/pages/OutFormPage')),
);
const OutListPage = Loadable(
  lazy(() => import('./views/transaction/out/outList/pages/OutListPage')),
);
const EntryFormPage = Loadable(
  lazy(
    () => import('./views/transaction/entry/entryRegister/pages/EntryFormPage'),
  ),
);
const EntryListPage = Loadable(
  lazy(() => import('./views/transaction/entry/entryList/pages/EntryListPage')),
);
const TransferFormPage = Loadable(
  lazy(
    () =>
      import(
        './views/transaction/transfer/transferRegister/pages/TransferFormPage'
      ),
  ),
);
const TransferListPage = Loadable(
  lazy(
    () =>
      import(
        './views/transaction/transfer/transferList/pages/TransferListPage'
      ),
  ),
);
const RestockListPage = Loadable(
  lazy(
    () =>
      import('./views/transaction/restock/restockList/pages/RestockListPage'),
  ),
);
const RestockFormPage = Loadable(
  lazy(
    () =>
      import(
        './views/transaction/restock/restockRegister/pages/RestockFormPage'
      ),
  ),
);
const OperationFormPage = Loadable(
  lazy(() => import('./views/tools/manageOperation/pages/OperationFormPage')),
);
const OperationListPage = Loadable(
  lazy(() => import('./views/tools/manageOperation/pages/OperationListPage')),
);

export type Route = {
  name: string;
  path: string;
  element: React.ComponentType;
  skipAuth?: boolean;
  moduleBase?: string;
};

const defaultRoutes: Route[] = [
  { name: 'Estoque', path: '', element: Home, moduleBase: 'warehouse' },
];

const toolsRoutes: Route[] = [
  {
    name: 'Gerenciar Estoques',
    path: 'warehouse/tools/manageWarehouse',
    element: WarehouseListPage,
  },
  {
    name: 'Cadastrar Estoques',
    path: 'warehouse/tools/manageWarehouse/add',
    element: WarehouseFormPage,
  },
  {
    name: 'Editar Estoques',
    path: 'warehouse/tools/manageWarehouse/edit/:id',
    element: WarehouseFormPage,
  },
  {
    name: 'Gerenciar Setores',
    path: 'warehouse/tools/manageSector',
    element: SectorListPage,
  },
  {
    name: 'Cadastrar Setores',
    path: 'warehouse/tools/manageSector/add',
    element: SectorFormPage,
  },
  {
    name: 'Editar Setores',
    path: 'warehouse/tools/manageSector/edit/:id',
    element: SectorFormPage,
  },
  {
    name: 'Gerenciar Fornecedores',
    path: 'warehouse/tools/manageSupplier',
    element: SupplierListPage,
  },
  {
    name: 'Cadastrar Fornecedores',
    path: 'warehouse/tools/manageSupplier/add',
    element: SupplierFormPage,
  },
  {
    name: 'Editar Fornecedores',
    path: 'warehouse/tools/manageSupplier/edit/:id',
    element: SupplierFormPage,
  },
  {
    name: 'Gerenciar Localizações',
    path: 'warehouse/tools/manageLocation',
    element: LocationListPage,
  },
  {
    name: 'Cadastrar Localizações',
    path: 'warehouse/tools/manageLocation/add',
    element: LocationFormPage,
  },
  {
    name: 'Editar Localizações',
    path: 'warehouse/tools/manageLocation/edit/:id',
    element: LocationFormPage,
  },
  {
    name: 'Gerenciar Operações',
    path: 'warehouse/tools/manageOperation',
    element: OperationListPage,
  },
  {
    name: 'Cadastrar Operações',
    path: 'warehouse/tools/manageOperation/add',
    element: OperationFormPage,
  },
  {
    name: 'Editar Operações',
    path: 'warehouse/tools/manageOperation/edit/:id',
    element: OperationFormPage,
  },
];

const productsRoutes: Route[] = [
  {
    name: 'Lista - Saldo',
    path: 'warehouse/products/balance',
    element: BalanceListPage,
  },
];

const transactionRoutes: Route[] = [
  {
    name: 'Listar',
    path: 'warehouse/transaction/transactionList',
    element: TransactionListPage,
  },
  {
    name: 'Entrada - Lista',
    path: 'warehouse/transaction/entry/list',
    element: EntryListPage,
  },
  {
    name: 'Entrada - Cadastro',
    path: 'warehouse/transaction/entry/add',
    element: EntryFormPage,
  },
  {
    name: 'Saída - Lista',
    path: 'warehouse/transaction/out/list',
    element: OutListPage,
  },
  {
    name: 'Saída - Cadastro',
    path: 'warehouse/transaction/out/add',
    element: OutFormPage,
  },
  {
    name: 'Transferência - Lista',
    path: 'warehouse/transaction/transfer/list',
    element: TransferListPage,
  },
  {
    name: 'Transferência - Cadastro',
    path: 'warehouse/transaction/transfer/add',
    element: TransferFormPage,
  },
  {
    name: 'Devolução - Lista',
    path: 'warehouse/transaction/restock/list',
    element: RestockListPage,
  },
  {
    name: 'Devolução - Cadastro',
    path: 'warehouse/transaction/restock/add',
    element: RestockFormPage,
  },
];

const useRoutesWarehouse = () => {
  const allRoutes = [
    ...toolsRoutes,
    ...defaultRoutes,
    ...productsRoutes,
    ...transactionRoutes,
  ];

  return useCreateRoutes(allRoutes as any);
};

export default useRoutesWarehouse;
