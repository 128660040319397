import { CssBaseline } from '@mui/material';
import { useRoutes } from 'react-router-dom';
import { AlphaTheme } from '@core/components/LayoutTheme';
import routes from './routes';
import ErrorBoundary from '@core/components/ErrorBoundary/ErrorBoundary';
import { SettingsProvider } from '@ilogix/contexts/SettingsContext';
import ToastConfig from '@core/lib/ToastConfig';
import TermOfUse from '@core/components/Modal/TermOfUse';
import JoyrideWrapper from '@core/components/Joyride/JoyrideWrapper';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const App = () => {
  const content = useRoutes(routes);

  return (
    <SettingsProvider>
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale="Pt-br"
        dateFormats={{ hours24h: 'HH:mm', fullDate: 'DD/MM/YYYY' }}
      >
        <AlphaTheme>
          <CssBaseline />
          <ToastConfig />
          <ErrorBoundary>
            <TermOfUse />
            <JoyrideWrapper />
            {content}
          </ErrorBoundary>
        </AlphaTheme>
      </LocalizationProvider>
    </SettingsProvider>
  );
};

export default App;
