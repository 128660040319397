import { request } from '@core/data/request';

// Interface base para usuário
interface User {
  name?: string;
}

// Interface base para grupo de usuário
interface UserGroup extends User {
  group_name?: string;
}

// Interface principal de usuário
export interface UserParams extends UserGroup {
  id?: number | string;
  email?: string;
  group_id?: string;
  active?: boolean;
}

// Interface para histórico
export interface UserHistoricData extends User {
  id: number;
  user_id: number;
  user_name: string;
  operation_log: string;
  email: string;
  group_name: string;
  created_at: string;
}

// Interface para listagem de usuários
export interface UserListParams extends UserGroup {
  id: number;
  active: number;
}

// Interface para logs de login
export interface UserLastLoginParams {
  created_at: string;
  user_ip: string;
  browser: string;
  operation_system: string;
  user_agent: string;
}

export interface Term {
  privacyPolicy: string;
  useTerm: string;
}

export const registerUser = async (userData: UserParams) => {
  return request<UserParams>({
    method: 'post',
    url: '/ilogix/user/register-user',
    data: {
      ...userData,
    },
  });
};

export const fetchUser = async (id: number | string): Promise<UserParams> => {
  return request<UserParams>({
    method: 'get',
    url: `/ilogix/user/edit-user/${id}`,
  });
};

export const editUser = async (id: number | string, userData: UserParams) => {
  return request<UserParams>({
    method: 'put',
    url: `/ilogix/user/edit-user/${id}`,
    data: {
      ...userData,
    },
  });
};

export const toggleUserStatus = async (
  id: number | string,
  active: boolean,
) => {
  return request<UserParams>({
    method: 'patch',
    url: `/ilogix/user/activate-user/${id}`,
    data: {
      active,
    },
  });
};

export const fetchUserLastLogin = async (
  id: number | string,
): Promise<UserLastLoginParams[]> => {
  return request<UserLastLoginParams[]>({
    method: 'get',
    url: `/ilogix/user/get-log-access/${id}`,
  });
};

export const fetchHistoricUser = async (
  id: number | string,
): Promise<UserHistoricData> => {
  return request<UserHistoricData>({
    method: 'get',
    url: `/ilogix/user/list-user-historic/${id}`,
  });
};

export const acceptTerm = async (termData: Term) => {
  return request<Term>({
    method: 'post',
    url: '/ilogix/user/accept-term',
    data: {
      ...termData,
    },
  });
};
