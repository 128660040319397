import React from 'react';
import ReactJoyride, { CallBackProps } from 'react-joyride';
import { useJoyrideStore } from '@core/store/joyrideStore';
import { useTheme } from '@mui/material';

const JoyrideWrapper: React.FC = () => {
  const theme = useTheme();
  const { steps, currentStep, setCurrentStep, isRunning, setIsRunning } =
    useJoyrideStore();

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status, index, type } = data;

    if (['finished', 'skipped'].includes(status)) {
      setIsRunning(false);
    } else if (type === 'step:after') {
      setCurrentStep(index + 1);
    }
  };

  return (
    <ReactJoyride
      steps={steps}
      stepIndex={currentStep}
      run={isRunning}
      continuous
      showProgress
      showSkipButton
      callback={handleJoyrideCallback}
      disableOverlayClose
      styles={{
        options: {
          zIndex: 10000,
          primaryColor: '#007bff',
          backgroundColor: theme.palette.primary.main,
          arrowColor: theme.palette.primary.main,
          textColor: '#fff',
          width: 600,
        },
        buttonClose: {
          display: 'none',
        },
        buttonBack: {
          display: 'none',
        },
        buttonSkip: {
          backgroundColor: theme.palette.error.main,
          color: '#fff',
          borderRadius: '5px',
          padding: '8px 16px',
          fontWeight: 'bold',
          textTransform: 'uppercase',
        },
        buttonNext: {
          backgroundColor: '#fff',
          color: theme.palette.primary.main,
          borderRadius: '5px',
          padding: '8px 16px',
          fontWeight: 'bold',
          textTransform: 'uppercase',
        },
      }}
      locale={{
        back: 'Anterior',
        close: 'Fechar',
        last: 'Finalizar',
        next: 'Próximo',
        skip: 'Pular',
        nextLabelWithProgress: 'Próximo (Passo {step} de {steps})',
      }}
    />
  );
};

export default JoyrideWrapper;
