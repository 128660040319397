import { create } from 'zustand';
import { Step } from 'react-joyride';

interface OrderedStep extends Step {
  order?: number;
}

interface JoyrideStore {
  steps: OrderedStep[];
  currentStep: number;
  isRunning: boolean;
  addSteps: (newSteps: OrderedStep[]) => void;
  setCurrentStep: (step: number) => void;
  setIsRunning: (running: boolean) => void;
  resetSteps: () => void;
}

export const useJoyrideStore = create<JoyrideStore>((set) => ({
  steps: [],
  currentStep: 0,
  isRunning: false,
  addSteps: (newSteps) =>
    set((state) => {
      const allSteps = [...state.steps, ...newSteps];
      const sortedSteps = allSteps.sort((a: any, b: any) => a.order - b.order);
      return { steps: sortedSteps };
    }),
  setCurrentStep: (step) => set(() => ({ currentStep: step })),
  setIsRunning: (running) => set(() => ({ isRunning: running })),
  resetSteps: () =>
    set(() => ({ steps: [], currentStep: 0, isRunning: false })),
}));
