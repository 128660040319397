import axios from 'axios';
import useAuthStore from '@core/store/authStore';
import { notify } from '@core/utils/notifyUtils';

const getAccessToken = () => useAuthStore.getState().accessToken;
const BASE_URL = import.meta.env.VITE_BASE_URL;
const notificationDebounce = new Set();
const disableUrlNotification = [
  '/ilogix/access-control/searched-functionality',
  '/ilogix/auth/logout',
  '/ilogix/user/accept-term',
];

const api = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
});

api.interceptors.request.use(
  (config) => {
    const accessToken = getAccessToken();

    if (
      config.url !== 'ilogix/auth/login' &&
      config.url !== 'ilogix/auth/logout' &&
      config.url !== 'ilogix/auth/activate-account' &&
      accessToken
    ) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    return Promise.resolve(config);
  },
  (error) => {
    Promise.reject(error);
  },
);

api.interceptors.response.use(
  (response) => {
    if (
      response.config.method !== 'get' &&
      response.config.url !== 'ilogix/auth/login' &&
      response.config.url !== 'ilogix/auth/verify-activate-account-token'
    ) {
      if (!disableUrlNotification.includes(response.config.url as string)) {
        const message =
          response.data?.response || 'Operação realizada com sucesso';

        if (!notificationDebounce.has(message)) {
          notificationDebounce.add(message);
          notify('success', message);

          setTimeout(() => {
            notificationDebounce.delete(message);
          }, 3000);
        }
      }
    }

    return response;
  },
  async (error) => {
    if (error.response) {
      const originalConfig = error.config;
      const { status, data } = error.response;
      console.warn(status);
      if (
        originalConfig.url !== 'ilogix/auth/login' &&
        originalConfig.url !== 'ilogix/auth/logout' &&
        originalConfig.url !== 'ilogix/auth/activate-account' &&
        status === 401
      ) {
        useAuthStore.getState().logout();
        notify(
          'error',
          'Sua sessão expirou. Por favor, realize o login novamente',
        );
        window.location.replace('/session/signin');
      }

      if (
        status === 400 &&
        originalConfig.url !== 'ilogix/auth/verify-activate-account-token'
      ) {
        const errorMessage = data?.response || 'Ocorreu um erro';
        if (!notificationDebounce.has(errorMessage)) {
          notificationDebounce.add(errorMessage);
          if (!disableUrlNotification.includes(originalConfig.url as string)) {
            notify('error', errorMessage);
          }

          setTimeout(() => {
            notificationDebounce.delete(errorMessage);
          }, 3000);
        }
      }

      return Promise.reject(error);
    } else {
      notify('error', 'Erro de rede. Verifique sua conexão.');
      return Promise.reject(error);
    }
  },
);

export { api };
