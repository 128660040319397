import { useCallback, useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  List,
  ListItem,
  Divider,
  FormControlLabel,
  Checkbox,
  Link,
  Stack,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import useAuthStore from '@core/store/authStore';
import { useMutation } from '@tanstack/react-query';
import {
  acceptTerm,
  Term,
} from '@ilogix/views/accessControl/ManageUser/services/userService';
import { useAuth, User } from '@ilogix/hooks/useAuth';
import { notify } from '@core/utils/notifyUtils';

const dialogContent = (
  <>
    <Typography variant="h5" gutterBottom>
      Sistema ILOGIX
    </Typography>

    <Typography
      variant="body2"
      align="right"
      gutterBottom
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        gap: 0.4,
        mb: 2,
      }}
    >
      <AccessTimeRoundedIcon fontSize="small" />
      Última atualização: 03 de dezembro de 2024
    </Typography>

    <Typography variant="body1">
      Este Termo visa registrar a manifestação livre, informada e inequívoca
      pela qual o Usuário do sistema Ilogix concorda com o tratamento de seus
      dados pessoais para finalidade específica, em conformidade com a Lei nº
      13.709 – Lei Geral de Proteção de Dados Pessoais (LGPD).
    </Typography>

    <Typography variant="body1">
      Ao manifestar sua aceitação para com o presente Termo, o Usuário do
      sistema Ilogix consente e concorda que a Ekan Soluções e Sistemas
      Integrados Ltda, CNPJ nº 23.993.897/0001-05, com sede na Rua Santa Cruz,
      2105 - Salas 1317, 1318, 1319, 1320, Bairro Vila Mariana, São Paulo/SP,
      CEP 04.121-002 doravante denominada Controladora, tome decisões referentes
      ao tratamento de seus dados pessoais.
    </Typography>

    <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
      Dados Pessoais utilizados pelo sistema Ilogix
    </Typography>

    <List>
      <ListItem sx={{ mb: -1 }}>• Nome completo;</ListItem>
      <ListItem sx={{ mb: -1 }}>• Sexo;</ListItem>
      <ListItem sx={{ mb: -1 }}>• Endereço de email;</ListItem>
      <ListItem sx={{ mb: -1 }}>
        • Número da Carteira de Identidade (RG);
      </ListItem>
      <ListItem sx={{ mb: -1 }}>
        • Número do Cadastro de Pessoa Física (CPF);
      </ListItem>
      <ListItem sx={{ mb: -1 }}>• CNS (Cartão Nacional do SUS);</ListItem>
      <ListItem sx={{ mb: -1 }}>• Número de telefone;</ListItem>
      <ListItem sx={{ mb: -1 }}>• Endereço;</ListItem>
      <ListItem sx={{ mb: -1 }}>• Prescrições médicas;</ListItem>
      <ListItem sx={{ mb: -1 }}>• Informações sobre medicamentos;</ListItem>
      <ListItem sx={{ mb: -1 }}>
        • Documentos relacionados a processos judiciais;
      </ListItem>
    </List>

    <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
      Finalidade do Tratamento dos Dados
    </Typography>

    <List>
      <ListItem>
        • Possibilitar que a Controladora identifique o Usuário e permita a este
        o acesso as ferramentas disponibilizadas pelo sistema Ilogix.
      </ListItem>
      <ListItem>
        • Coletar, classificar, utilizar, processar, reproduzir, armazenar e
        arquivar seus dados pessoais e o histórico de atividades realizadas no
        sistema Ilogix.
      </ListItem>
    </List>

    <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
      Compartilhamento de Dados
    </Typography>

    <Typography variant="body1">
      A Controladora fica autorizada a compartilhar seus dados pessoais apenas
      para cumprir uma obrigação legal, observados os princípios e as garantias
      estabelecidas pela Lei nº 13.709.
    </Typography>

    <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
      Segurança dos Dados
    </Typography>

    <Typography variant="body1">
      Controladora responsabiliza-se pela manutenção de medidas de segurança,
      técnicas e administrativas aptas a proteger os dados pessoais do Usuário
      de acessos não autorizados ao sistema Ilogix e de situações acidentais ou
      ilícitas de destruição, perda, alteração, comunicação ou qualquer forma de
      tratamento inadequado ou ilícito. Em conformidade ao art. 48 da Lei nº
      13.709, o Controlador comunicará ao Usuário e à Autoridade Nacional de
      Proteção de Dados (ANPD) a ocorrência de incidente de segurança que possa
      acarretar risco ou dano relevante ao Usuário.
    </Typography>

    <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
      Término do Tratamento dos Dados
    </Typography>

    <Typography variant="body1">
      A Controladora poderá manter e tratar os dados pessoais do Usuário durante
      todo o período em que os mesmos forem pertinentes ao alcance das
      finalidades listadas neste Termo.
    </Typography>

    <Typography variant="body1">
      Todos os dados pessoais do Usuário no sistema Ilogix são anonimizados, sem
      possibilidade de associação ao indivíduo e portanto mantidos por período
      indefinido.
    </Typography>

    <Typography variant="body1">
      O Usuário poderá através da página de configuração do seu perfil, a
      qualquer momento, inativar este consentimento, ficando ciente de que não
      poderá mais a partir deste, acessar o sistema Ilogix.
    </Typography>
  </>
);

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: 16,
    [theme.breakpoints.up('sm')]: {
      minWidth: '600px',
    },
  },
  '& .MuiBackdrop-root': {
    backgroundColor: 'rgba(0, 0, 0, 0.89)',
  },
}));

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  padding: theme.spacing(2),
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: theme.spacing(2),
  marginTop: theme.spacing(2),
}));

const TermOfUse = () => {
  const { handleLogout } = useAuth();
  const [open, setOpen] = useState(false);
  const [aceitoTermoUso, setAceitoTermoUso] = useState(false);
  const [aceitoPolitica, setAceitoPolitica] = useState(false);
  const { isAuthenticated, user, updateUser, isInitialised } = useAuthStore();

  useEffect(() => {
    if (isAuthenticated && isInitialised && user && !location.pathname.includes('/session')) {
      const termoAceito =
        user?.useTerm && user?.privacyPolicy;

      const timer = setTimeout(() => {
        setOpen(!termoAceito);
      }, 300);

      return () => clearTimeout(timer);
    }
  }, [isAuthenticated, isInitialised, user]);

  const mutationCreate = useMutation({
    mutationFn: ({ values }: { values: Term }) => acceptTerm(values),
    onSuccess: () => {
      updateUser({
        ...user,
        useTerm: '1.0',
        privacyPolicy: '1.0',
      } as unknown as User);
      setOpen(false);
      handleLogout();
      notify('success', 'Termo aceito com sucesso.');
      notify('info', 'Por favor, faca o login novamente.');    
    },
    onError: (error: unknown) => {
      console.error('Error accept term:', error);
    },
  });

  const handleAceitarTermo = useCallback(() => {
    const dadosAceite = {
      useTerm: '1.0',
      privacyPolicy: '1.0',
    };

    mutationCreate.mutate({ values: dadosAceite });
  }, []);

  const handleChangeTermoUso = useCallback((event: any) => {
    setAceitoTermoUso(event.target.checked);
  }, []);

  const handleChangePolitica = useCallback((event: any) => {
    setAceitoPolitica(event.target.checked);
  }, []);

  if (!isAuthenticated || !open) {
    return null;
  }

  return (
    <StyledDialog open={open} maxWidth="md" fullWidth disableEscapeKeyDown>
      <StyledDialogTitle>
        <Typography variant="h5" component="h2">
          Termo de Consentimento para Tratamento de Dados
        </Typography>
      </StyledDialogTitle>

      <StyledDialogContent>
        <Box sx={{ p: 2 }}>
          {dialogContent}

          <Divider sx={{ my: 2 }} />

          <Stack spacing={1} sx={{ alignItems: 'center' }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={aceitoTermoUso}
                  onChange={handleChangeTermoUso}
                />
              }
              label={
                <Typography variant="body2">
                  Li e aceito os{' '}
                  <Link
                    href="https://dpo.privacytools.com.br/policy-view/5ZrvVe2Mz/3/termo-de-uso-ilogix/pt_BR?s=1733252680545"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Termos de Uso
                  </Link>
                </Typography>
              }
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={aceitoPolitica}
                  onChange={handleChangePolitica}
                />
              }
              label={
                <Typography variant="body2">
                  Li e aceito a{' '}
                  <Link
                    href="https://dpo.privacytools.com.br/policy-view/JmDjxE0Rw/1/policy/pt_BR?s=1733240881392"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Política de Privacidade
                  </Link>
                </Typography>
              }
            />
          </Stack>
        </Box>
      </StyledDialogContent>

      <DialogActions
        sx={{
          justifyContent: 'center',
          padding: 3,
          borderTop: '1px solid',
          borderColor: 'grey.200',
        }}
      >
        <Button
          variant="contained"
          size="large"
          onClick={handleAceitarTermo}
          disabled={!aceitoTermoUso || !aceitoPolitica}
          sx={{
            minWidth: 200,
            borderRadius: 2,
            textTransform: 'none',
          }}
        >
          Continuar
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

export default TermOfUse;
