import Loadable from '@core/components/LayoutTheme/Loadable';
import { useCreateRoutes } from '@ilogix/hooks/useRoute';
import { lazy } from 'react';

const CompanyListPage = Loadable(
  lazy(
    () => import('./views/accessControl/ManageCompany/pages/CompanyListPage'),
  ),
);
const CompanyFormPage = Loadable(
  lazy(
    () => import('./views/accessControl/ManageCompany/pages/CompanyFormPage'),
  ),
);
const UserListPage = Loadable(
  lazy(() => import('./views/accessControl/ManageUser/pages/UserListPage')),
);
const UserFormPage = Loadable(
  lazy(() => import('./views/accessControl/ManageUser/pages/UserFormPage')),
);
const GroupListPage = Loadable(
  lazy(() => import('./views/accessControl/ManageGroup/pages/GroupListPage')),
);
const GroupFormPage = Loadable(
  lazy(() => import('./views/accessControl/ManageGroup/pages/GroupFormPage')),
);
const GroupManagePage = Loadable(
  lazy(() => import('./views/accessControl/ManageGroup/pages/GroupManagePage')),
);
const ProductFormPage = Loadable(
  lazy(() => import('./views/tools/manageProduct/pages/ProductFormPage')),
);
const ProductListPage = Loadable(
  lazy(() => import('./views/tools/manageProduct/pages/ProductListPage')),
);
const UnitFormPage = Loadable(
  lazy(() => import('./views/tools/manageUnit/pages/UnitFormPage')),
);
const UnitListPage = Loadable(
  lazy(() => import('./views/tools/manageUnit/pages/UnitListPage')),
);
const Home = Loadable(lazy(() => import('./views/home/pages/Home')));
const ProfilePage = Loadable(
  lazy(() => import('./views/profile/ManageProfile/pages/ProfilePage')),
);
const LogListPage = Loadable(
  lazy(() => import('./views/accessControl/AccessLog/pages/LogListPage')),
);
const LabelManagePage = Loadable(
  lazy(() => import('./views/accessControl/ManageLabel/pages/LabelManagePage')),
);
const IntegrationDashboardPage = Loadable(
  lazy(
    () =>
      import('./views/dashboard/Integrations/pages/IntegrationDashboardPage'),
  ),
);
const Docs = Loadable(lazy(() => import('./views/docs/AccessDocs/pages/Docs')));

export type Route = {
  name: string;
  path: string;
  element: React.ComponentType;
  skipAuth?: boolean;
};

const accessControlRoutes: Route[] = [
  {
    name: 'Gerenciar Empresas',
    path: 'accessControl/manageCompany',
    element: CompanyListPage,
  },
  {
    name: 'Cadastrar Empresa',
    path: 'accessControl/manageCompany/add',
    element: CompanyFormPage,
  },
  {
    name: 'Editar Empresa',
    path: 'accessControl/manageCompany/edit/:id',
    element: CompanyFormPage,
  },
  {
    name: 'Gerenciar Usuários',
    path: 'accessControl/manageUser',
    element: UserListPage,
  },
  {
    name: 'Cadastrar Usuário',
    path: 'accessControl/manageUser/add',
    element: UserFormPage,
  },
  {
    name: 'Editar Usuário',
    path: 'accessControl/manageUser/edit/:id',
    element: UserFormPage,
  },
  {
    name: 'Gerenciar Grupos',
    path: 'accessControl/manageGroup',
    element: GroupListPage,
  },
  {
    name: 'Cadastrar Grupo',
    path: 'accessControl/manageGroup/add',
    element: GroupFormPage,
  },
  {
    name: 'Editar Grupo',
    path: 'accessControl/manageGroup/edit/:id',
    element: GroupFormPage,
  },
  {
    name: 'Gerenciar',
    path: 'accessControl/manageGroup/manage/:id',
    element: GroupManagePage,
  },
  {
    name: 'Log de Acesso',
    path: 'accessControl/accessLog',
    element: LogListPage,
  },
  {
    name: 'Gerenciar URL',
    path: 'accessControl/manageLabel',
    element: LabelManagePage,
  },
  {
    name: 'Acessar Docs',
    path: 'docs/AccessDocs',
    element: Docs,
    skipAuth: true,
  },
];

const defaultRoutes: Route[] = [
  { name: 'Home', path: 'home', element: Home, skipAuth: true },
];
const dashboardRoutes: Route[] = [
  {
    name: 'Integrações',
    path: 'dashboard/integration',
    element: IntegrationDashboardPage,
  },
];
const profileRoutes: Route[] = [
  {
    name: 'Gerenciar Perfil',
    path: 'profile/manageProfile',
    element: ProfilePage,
  },
];
const toolsRoutes: Route[] = [
  {
    name: 'Gerenciar Produtos',
    path: 'tools/manageProduct',
    element: ProductListPage,
  },
  {
    name: 'Cadastrar Produto',
    path: 'tools/manageProduct/add',
    element: ProductFormPage,
  },
  {
    name: 'Editar Produto',
    path: 'tools/manageProduct/edit/:id',
    element: ProductFormPage,
  },
  {
    name: 'Gerenciar Unidades',
    path: 'tools/manageUnit',
    element: UnitListPage,
  },
  {
    name: 'Cadastrar Unidade',
    path: 'tools/manageUnit/add',
    element: UnitFormPage,
  },
  {
    name: 'Editar Unidade',
    path: 'tools/manageUnit/edit/:id',
    element: UnitFormPage,
  },
];

const useRoutesIlogix = () => {
  const allRoutes = [
    ...accessControlRoutes,
    ...defaultRoutes,
    ...profileRoutes,
    ...dashboardRoutes,
    ...toolsRoutes,
  ];

  return useCreateRoutes(allRoutes as any);
};

export default useRoutesIlogix;
