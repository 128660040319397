import { Route, Routes } from 'react-router-dom';
import { AuthorizedPaths } from '@warehouse/path';
import RouteErrorBoundary from '@core/components/ErrorBoundary/RouteErrorBoundary';
import Loadable from '@core/components/LayoutTheme/Loadable';
import { lazy } from 'react';

const NotFound = Loadable(lazy(() => import('@ilogix/views/session/NotFound')));

const WarehouseModule = () => {
  const warehouseRoutes = AuthorizedPaths();

  return (
    <RouteErrorBoundary>
      <Routes>
        {warehouseRoutes.map((route: any, index: number) => {
          return (
            <Route
              key={index}
              path={route.path.replace('warehouse/', '')}
              element={<route.element />}
            />
          );
        })}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </RouteErrorBoundary>
  );
};

export default WarehouseModule;
